import { Component } from '@angular/core';
import { Auth, authState } from '@angular/fire/auth';
import {
  collection,
  collectionData,
  Firestore,
  orderBy,
  query,
  where,
} from '@angular/fire/firestore';
import { EMPTY, Observable } from 'rxjs';
import { Address } from '../types';
import { AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-labels',
  templateUrl: './labels.component.html',
  styleUrls: ['../common-styles.css', './labels.component.css'],
})
export class LabelsComponent implements AfterViewInit {
  addresses: Observable<Address[] | undefined> = EMPTY;

  constructor(
    public firestore: Firestore,
    public auth: Auth,
  ) {}

  ngAfterViewInit() {
    authState(this.auth).subscribe((user) => {
      const addressCollection = collection(this.firestore, 'addresses').withConverter<Address>({
        fromFirestore: (snapshot) => snapshot.data() as Address,
        toFirestore: (it) => it,
      });
      this.addresses = collectionData(
        query(addressCollection, where('owner', '==', user?.uid), orderBy('familyname')),
      );
    });
  }
}
