<form [formGroup]="addressForm" (ngSubmit)="onSubmit()">
  <h2 mat-dialog-title>{{ verb }} Address</h2>
  <mat-dialog-content class="mat-typography">
    <p>
      <mat-form-field appearance="outline">
        <mat-label>Family Name</mat-label>
        <input matInput formControlName="familyname" required />
      </mat-form-field>
    </p>
    <p>
      <mat-form-field appearance="outline">
        <mat-label>Address</mat-label>
        <textarea matInput formControlName="address" required></textarea>
      </mat-form-field>
    </p>
    <p>
      <mat-form-field appearance="outline">
        <mat-label>City</mat-label>
        <input matInput formControlName="city" required />
      </mat-form-field>
    </p>
    <p>
      <mat-form-field appearance="outline">
        <mat-label>State</mat-label>
        <input matInput formControlName="state" required />
      </mat-form-field>
    </p>
    <p>
      <mat-form-field appearance="outline">
        <mat-label>Zip</mat-label>
        <input matInput formControlName="zip" required />
      </mat-form-field>
    </p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button
      mat-button
      color="primary"
      type="submit"
      [disabled]="!addressForm.valid"
      cdkFocusInitial
    >
      {{ verb }}
    </button>
  </mat-dialog-actions>
</form>
