import { AddAddressComponent } from '../add-address/add-address.component';
import { AddressWithId } from '../types';
import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { Auth, authState, User } from '@angular/fire/auth';
import {
  collection,
  collectionData,
  deleteDoc,
  doc,
  Firestore,
  orderBy,
  query,
  where,
  updateDoc,
} from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { EMPTY, Observable } from 'rxjs';
import { utils, writeFile } from 'xlsx';

@Component({
  selector: 'app-addressbook',
  templateUrl: './addressbook.component.html',
  styleUrls: ['../common-styles.css', './addressbook.component.css'],
})
export class AddressBookComponent implements AfterViewInit {
  displayedColumns: string[] = ['export', 'familyname', 'address', 'city', 'state', 'zip', 'edit'];
  dataSource: MatTableDataSource<AddressWithId>;
  fullDataSet: AddressWithId[] = [];
  readonly user: Observable<User | null> = EMPTY;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    public firestore: Firestore,
    public auth: Auth,
  ) {
    this.user = authState(this.auth);
  }

  ngAfterViewInit() {
    this.user.subscribe((user) => {
      if (user) {
        const addressCollection = collection(
          this.firestore,
          'addresses',
        ).withConverter<AddressWithId>({
          fromFirestore: (snapshot) => {
            const obj = snapshot.data() as AddressWithId;
            obj.id = snapshot.id;
            if (obj.export === undefined) {
              obj.export = true;
            }
            return obj;
          },
          toFirestore: (it) => it,
        });
        collectionData(
          query(addressCollection, where('owner', '==', user?.uid), orderBy('familyname')),
        ).subscribe((val) => {
          this.fullDataSet = val;
          this.dataSource = new MatTableDataSource(val);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        });
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  filterExported(checked: boolean) {
    const filteredData: AddressWithId[] = [];
    this.fullDataSet.forEach((element) => {
      if (!checked || element.export) {
        filteredData.push(element);
      }
    });

    this.dataSource = new MatTableDataSource(filteredData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  toggleExport(address: AddressWithId, checked: boolean) {
    address.export = checked;
    const updateData = {
      export: checked,
    };
    updateDoc(doc(this.firestore, 'addresses', address.id), updateData);
  }

  editAddress(address: AddressWithId) {
    this.dialog.open(AddAddressComponent, {
      data: { address: address },
      width: '70%',
    });
  }

  deleteAddress(address: AddressWithId) {
    const dialogRef = this.dialog.open(DeleteAddressDialogComponent);
    dialogRef.afterClosed().subscribe((result) => {
      if (result === true) {
        deleteDoc(doc(this.firestore, 'addresses', address.id));
      }
    });
  }

  addAddress() {
    this.dialog.open(AddAddressComponent, {
      width: '70%',
    });
  }

  exportCsv() {
    const csvData: {
      familyname: string;
      address: string;
      city: string;
      state: string;
      zip: string;
    }[] = [];

    this.dataSource.data.forEach((element: AddressWithId) => {
      csvData.push({
        familyname: element.familyname,
        address: element.address,
        city: element.city,
        state: element.state,
        zip: element.zip,
      });
    });

    const ws = utils.json_to_sheet(csvData);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Addresses');
    writeFile(wb, 'addresses.xlsx');
  }
}

@Component({
  selector: 'app-delete-address-dialog',
  template: `
    <h2 mat-dialog-title>Delete?</h2>
    <mat-dialog-content class="mat-typography">
      <p>Are you sure you want to delete this address?</p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
      <button mat-button [mat-dialog-close]="true" color="warn">Delete</button>
    </mat-dialog-actions>
  `,
})
export class DeleteAddressDialogComponent {}
