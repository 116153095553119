<app-header></app-header>

<div class="container" *ngIf="user | async as user">
  <div class="row">
    <div class="header-row">
      <h1>Contacts</h1>
      <span class="spacer"></span>
      <button mat-fab color="primary" (click)="addAddress()">
        <mat-icon aria-hidden="false">add</mat-icon>
      </button>
      <button mat-fab color="primary" (click)="exportCsv()">Export</button>
    </div>
    <mat-form-field>
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event)" #input />
    </mat-form-field>

    <p><mat-checkbox (change)="filterExported($event.checked)">Show Only Exported</mat-checkbox></p>

    <div class="mat-elevation-z8">
      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        matSortActive="familyname"
        matSortDirection="asc"
        matSortDisableClear
      >
        <ng-container matColumnDef="export">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Export</th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              [checked]="row.export"
              (change)="toggleExport(row, $event.checked)"
            ></mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="familyname">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell (click)="editAddress(row)" *matCellDef="let row">{{ row.familyname }}</td>
        </ng-container>

        <ng-container matColumnDef="address">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Address</th>
          <td mat-cell (click)="editAddress(row)" *matCellDef="let row">{{ row.address }}</td>
        </ng-container>

        <ng-container matColumnDef="city">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>City</th>
          <td mat-cell (click)="editAddress(row)" *matCellDef="let row">{{ row.city }}</td>
        </ng-container>

        <ng-container matColumnDef="state">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>State</th>
          <td mat-cell (click)="editAddress(row)" *matCellDef="let row">{{ row.state }}</td>
        </ng-container>

        <ng-container matColumnDef="zip">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Zip</th>
          <td mat-cell (click)="editAddress(row)" *matCellDef="let row">{{ row.zip }}</td>
        </ng-container>

        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef></th>
          <td
            mat-cell
            [matMenuTriggerFor]="addressEditMenu"
            [matMenuTriggerData]="{ address: row }"
            *matCellDef="let row"
          >
            <mat-icon>more_vert</mat-icon>
          </td>
          <mat-menu #addressEditMenu="matMenu">
            <ng-template matMenuContent let-address="address">
              <button mat-menu-item (click)="editAddress(address)">
                <mat-icon>edit</mat-icon>
                <span>Edit</span>
              </button>
              <button mat-menu-item (click)="deleteAddress(address)">
                <mat-icon>delete</mat-icon>
                <span>Delete</span>
              </button>
            </ng-template>
          </mat-menu>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="3" *ngIf="input.value === ''; else emptyFilter">
            You don't have any contacts.
          </td>
          <ng-template #emptyFilter>
            <td class="mat-cell" colspan="3">
              No addresses matching the filter "{{ input.value }}"
            </td>
          </ng-template>
        </tr>
      </table>

      <mat-paginator [pageSize]="100" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
  </div>
</div>
